//
// Stepper
//

// Base
.stepper {
  // Content
  [data-kt-stepper-element="info"],
  [data-kt-stepper-element="content"] {
    display: none;

    &.current {
      display: flex;
    }
  }

  // Enable cursor pointer on clickable steppers
  .stepper-item[data-kt-stepper-action="step"] {
    cursor: pointer;
  }

  // States
  & {
    [data-kt-stepper-action="previous"] {
      display: none;
    }

    [data-kt-stepper-action="next"] {
      display: inline-block;
    }

    [data-kt-stepper-action="submit"] {
      display: none;
    }
  }

  &.first {
    [data-kt-stepper-action="previous"] {
      display: none;
    }

    [data-kt-stepper-action="next"] {
      display: inline-block;
    }

    [data-kt-stepper-action="submit"] {
      display: none;
    }
  }

  &.between {
    [data-kt-stepper-action="previous"] {
      display: inline-block;
    }

    [data-kt-stepper-action="next"] {
      display: inline-block;
    }

    [data-kt-stepper-action="submit"] {
      display: none;
    }
  }

  &.last {
    [data-kt-stepper-action="previous"] {
      display: inline-block;
    }

    [data-kt-stepper-action="next"] {
      display: none;
    }

    [data-kt-stepper-action="submit"] {
      display: inline-block;
    }

    [data-kt-stepper-action="submit"].btn-flex {
      display: flex;
    }
  }
}