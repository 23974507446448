//
// Shape
// 

.xehagon {
  //$edges-number: 6, $main-radius: 30%, $rounding-radius: 10%, $rotated: true, $precision: 20
  @include shape(6);
}

.octagon {
  //$edges-number: 6, $main-radius: 30%, $rounding-radius: 10%, $rotated: true, $precision: 20
  @include shape(8);
}